@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.inner {
  margin: 0 auto;
  max-width: $max-inner-width;
  .scrollContent {
    position: relative;
    z-index: $zIndex-1;
  }
}
.homeSeachContent {
  padding: 24px 0 10px;
}
.headerFixed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 180px;
  background-color: $reddish-orange;
}
.columnsLayout {
  width: 100%;
  .left {
    width: 62%;
  }
  .right {
    width: 33%;
    padding-bottom: 62px;
  }
}
.horAdvertise {
  margin: 32px 0;
  height: 0;
  padding-bottom: 9%;
  position: relative;
  .horAdvertiseContent {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

.tagsToolTip {
  padding: 8px 12px !important;
  border-radius: 8px !important;
  background-color: rgba(51, 51, 51, 0.8) !important;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 1.67;
}
